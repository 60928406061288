/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<i class="icon-home"></i>
```
*/
.icon-arrow {
  background-image: url(../images/sprite.png);
  background-position: -114px -93px;
  width: 17px;
  height: 15px;
}
.icon-facebook-off {
  background-image: url(../images/sprite.png);
  background-position: -25px -93px;
  width: 9px;
  height: 16px;
}
.icon-facebook-on {
  background-image: url(../images/sprite.png);
  background-position: -81px -93px;
  width: 8px;
  height: 15px;
}
.icon-gallery {
  background-image: url(../images/sprite.png);
  background-position: -94px -93px;
  width: 15px;
  height: 15px;
}
.icon-map {
  background-image: url(../images/sprite.png);
  background-position: -119px -34px;
  width: 29px;
  height: 21px;
}
.icon-mouse-scroll {
  background-image: url(../images/sprite.png);
  background-position: -50px 0px;
  width: 17px;
  height: 44px;
}
.icon-nav-back-large-black {
  background-image: url(../images/sprite.png);
  background-position: -95px -50px;
  width: 16px;
  height: 30px;
}
.icon-nav-back-large {
  background-image: url(../images/sprite.png);
  background-position: 0px -50px;
  width: 21px;
  height: 38px;
}
.icon-nav-back-mini {
  background-image: url(../images/sprite.png);
  background-position: -137px -60px;
  width: 12px;
  height: 18px;
}
.icon-nav-back-small {
  background-image: url(../images/sprite.png);
  background-position: -53px -50px;
  width: 16px;
  height: 32px;
}
.icon-nav-burger {
  background-image: url(../images/sprite.png);
  background-position: 0px -134px;
  width: 15px;
  height: 11px;
}
.icon-nav-down-mini {
  background-image: url(../images/sprite.png);
  background-position: -124px -115px;
  width: 19px;
  height: 11px;
}
.icon-nav-next-large-black {
  background-image: url(../images/sprite.png);
  background-position: -74px -50px;
  width: 16px;
  height: 30px;
}
.icon-nav-next-large {
  background-image: url(../images/sprite.png);
  background-position: -26px -50px;
  width: 22px;
  height: 38px;
}
.icon-plus-large {
  background-image: url(../images/sprite.png);
  background-position: -65px -115px;
  width: 13px;
  height: 13px;
}
.icon-plus {
  background-image: url(../images/sprite.png);
  background-position: -20px -134px;
  width: 7px;
  height: 8px;
}
.icon-round-check {
  background-image: url(../images/sprite.png);
  background-position: -72px 0px;
  width: 42px;
  height: 44px;
}
.icon-round-play {
  background-image: url(../images/sprite.png);
  background-position: -119px 0px;
  width: 30px;
  height: 29px;
}
.icon-round-plus {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 45px;
  height: 45px;
}
.icon-seta-down {
  background-image: url(../images/sprite.png);
  background-position: -63px -93px;
  width: 13px;
  height: 16px;
}
.icon-seta-up {
  background-image: url(../images/sprite.png);
  background-position: -119px -60px;
  width: 13px;
  height: 18px;
}
.icon-share {
  background-image: url(../images/sprite.png);
  background-position: -136px -93px;
  width: 12px;
  height: 15px;
}
.icon-slider-off {
  background-image: url(../images/sprite.png);
  background-position: -46px -115px;
  width: 14px;
  height: 13px;
}
.icon-slider-on {
  background-image: url(../images/sprite.png);
  background-position: -108px -115px;
  width: 11px;
  height: 12px;
}
.icon-square-video {
  background-image: url(../images/sprite.png);
  background-position: 0px -115px;
  width: 12px;
  height: 14px;
}
.icon-til {
  background-image: url(../images/sprite.png);
  background-position: -32px -134px;
  width: 24px;
  height: 7px;
}
.icon-twitter-off {
  background-image: url(../images/sprite.png);
  background-position: 0px -93px;
  width: 20px;
  height: 17px;
}
.icon-twitter-on {
  background-image: url(../images/sprite.png);
  background-position: -39px -93px;
  width: 19px;
  height: 16px;
}
.icon-youtube-off {
  background-image: url(../images/sprite.png);
  background-position: -83px -115px;
  width: 20px;
  height: 13px;
}
.icon-youtube-on {
  background-image: url(../images/sprite.png);
  background-position: -17px -115px;
  width: 24px;
  height: 14px;
}
