
/* -- header -- */
.lnk_voltar {
    display:inline-block; margin-right: 10px;
}



/* -- MENU Tabs -- */
.module-menu-textos {
    margin-bottom:0;
    transition: margin ease-in-out 200ms;
    font-size:0;
}
.module-menu-textos.open {
    margin-bottom: 50px;
}

.module-menu-textos li {
    display: inline-block;
    font-size: 24px;
    line-height: 40px;
   
    color: #3b3b3b;
    cursor: pointer;
    position: static;
    font-weight:300;
   
    z-index:2;
}
.module-menu-textos li span {
    z-index:2;
    background-color:#f7f7f7;
    display:block;
    padding-right: 20px;
    
}


.module-menu-textos li.active {
    color: #d40707;
}

.module-menu-textos li ul {
    position: absolute;
    z-index:1;
    white-space: nowrap;
    left: 0;
    overflow: hidden;
    top: 0;

    opacity:0;
    transition: top ease-in-out 200ms,opacity ease-in-out 200ms;
}

.module-menu-textos li.active ul {
    top: 100%;
    opacity:1;
}

.module-menu-textos li ul li {
    font-size: 20px;
    line-height: 25px;
    padding-right:20px;
}




#editores {
    margin-bottom:30px;
}

#editores .painelshow {
    display:none;
    position: relative;
    top:30px;
    opacity:0;
}
#editores .painelshow.animacao {
    transition: opacity ease-in-out 200ms, top ease-in-out 200ms;
}

#editores .painelshow.open {
    top:0;
    opacity:1;
}


#editores .subpainelshow {
    display:none;
    position: relative;
    left:30px;
    opacity:0;
}
#editores .subpainelshow.animacao {
    transition: opacity ease-in-out 200ms, left ease-in-out 200ms;
}

#editores .subpainelshow.open {
    left:0;
    opacity:1;
}
#div_edicao .text-table td {
    padding:5px; 
    border:1px solid #ddd;
}


.painelshow .addHorarios {
    width: 29%;
    display: inline-block;
    vertical-align: top;
}

.painelshow .horario_holder {
    width: 69%;
    display: inline-block;
    vertical-align: top;
}

.painelshow .recorrente {
    display: none;
}

.painelshow .recorrente.show {
    display: block;
}

.painelshow .lbl_add_day {
    border: 0;
    display: block;
    padding: 5px 20px;
    text-align: center;
    background-color: #d40707;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    margin-top: 20px;
    max-width: 140px;
}





/* Edição de produto */
#div_edicao { display: table; width: 92%; vertical-align: top; background-color: #F7F7F7; padding: 30px 4%; height: 500px; -webkit-border-bottom-right-radius: 4px; -webkit-border-bottom-left-radius: 4px; -moz-border-radius-bottomright: 4px; -moz-border-radius-bottomleft: 4px; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; border-top: 6px solid #E3E3E3; }
#div_edicao .tr { display: table-row; }
#div_edicao .td_head { display: block; width: 100%; float:left; padding-right: 4%;min-width:665px; }
#div_edicao .td_head .spacerv{ display: block; width: 76%;  }
#div_edicao .td_left { display: block; width: 76%; float:left; text-align: left; vertical-align: top; border-right: 1px solid #E3E3E3; padding-right: 4%;min-width:665px; }
#div_edicao .td_right { display: block; width: 16%;float:right; vertical-align: top; padding-left: 3%; padding-top: 11px; min-width:150px;}
#div_edicao .td_right .titulo_opcoes { border-bottom: 6px solid #EEEEEE; clear: both; color: #d40707; font-size: 26px; padding-bottom: 20px;margin-bottom:20px;  line-height:1.1em;}



label { display: inline!important; margin-bottom: 0; margin-left: 6px; }


#div_edicao .input_323 { width: 99%; font-size: 14px; }


#div_edicao.onecol .td_head {width:100%;  border-right:0;}
#div_edicao.onecol .td_head .spacerv{width:100%;  border-right:0;}
#div_edicao.onecol .td_left {width:100%;  border-right:0;}
#div_edicao.onecol .td_right {width:100%; padding-left:0; float:left;}
#div_edicao.onecol .td_right .opcoes_grupo { border-right: 1px solid #EEEEEE; float: left; margin-right: 30px; min-width: 130px; padding-right: 30px; }

.td_right select { width:100%;border-radius:3px;}
.field_error { font-size: 11px; color: red; font-weight: bold; }

 #mapa { width:100%; height:400px;}



#div_edicao .painel_fotos,#div_edicao .painel_docs { overflow:hidden;border-top:1px solid #ddd; padding-top:20px;}

#div_edicao .media_item { border: 1px solid #DDDDDD; display:inline-block; vertical-align:top; margin-right: 10px; padding-top: 5px; width: 200px;margin-bottom:10px; background-color:#F7F7F7; padding-bottom:10px;}
#div_edicao .media_item .pic { height: 145px; margin: 0 auto 5px; overflow: hidden; width: 190px;text-align:center;cursor:move }
#div_edicao .media_item .pic img{ height:100%;}
#div_edicao .media_item input[type="text"] {margin-left:5px;width: 150px;     margin-bottom: 5px;    font-size: 11px;box-sizing: border-box;height: 26px;}

#div_edicao .checks {
    margin-top:10px;
}
#div_edicao .check { float: left; margin-left: 7px; width: 45%;white-space:nowrap }
#div_edicao .check label { font-size:9px;white-space:nowrap;text-overflow:ellipsis;}

#div_edicao .btn_remove_media { background: url("../images/trash.png") no-repeat scroll left center transparent; cursor: pointer; float: right; font-size: 10px; font-weight: bold; margin-left: 10px; margin-right: 10px; margin-top: 5px; padding-left: 15px; }
#div_edicao .btn_remove_media:hover { color:#666}
#uploader_fotos,#uploader_docs { width:100%; clear:both;overflow:hidden;margin-bottom:10px; min-height:100px;background:url(../images/progress.gif) no-repeat center center}
#uploader_fotos.loaded, #uploader_docs.loaded {
    background:none;
}



#uploader_iframe, #uploader_iframe_docs { width:100%; height:100%;}

#div_edicao .rel_item { border: 1px solid #DDDDDD; float: left; height: 67px; margin-bottom: 9px; margin-right: 9px; width: 200px; overflow:hidden;}
#div_edicao .rel_item .pic { float: left; height: 60px; margin: 5px; overflow: hidden; text-align: center; width: 90px; }
#div_edicao .rel_item .check { display: table-cell; height: 100%; margin-left: 7px; vertical-align: middle; white-space: normal; width: 45%; }
#div_edicao .rel_item .check label { display: block; float: left; font-size: 11px; text-overflow: ellipsis; white-space: normal; line-height: 1.2em; }
#div_edicao .rel_item .rel_check { display:block;margin-top:5px;}
#div_edicao .rel_item .rel_check input{ width:100%; text-align:center}

#div_edicao .perm_item { border: 1px solid #DDDDDD; float: left; height: 55px; margin-bottom: 9px; margin-right: 9px; width: 175px; overflow:hidden;}
#div_edicao .perm_item .pic { float: left; height: 45px; margin: 5px; overflow: hidden; text-align: center; width: 45px; }
#div_edicao .perm_item .check { display: block; height: 100%; margin-left: 2px; vertical-align: middle; white-space: normal; width: 60%; }
#div_edicao .perm_item .lbl { display: block; float: left; font-size: 11px; padding:0; margin-left:2px; margin-top:3px; width:60%; }
#div_edicao .perm_item .rel_check { display:block;margin-top:5px;}
#div_edicao .perm_item .rel_check input{ width:100%; text-align:center}


 #div_edicao .validade_checks { display: none; }
#div_edicao #submenu { height: 0px; overflow: hidden; margin-bottom: 20px; }
#div_edicao #submenuTextos {  overflow: hidden;  }
#div_edicao .submenuitem { color: #3B3B3B; cursor: pointer; float: left; font-family: 'open_sans_lightregular',Arial; font-size: 20px; line-height: 25px; padding-right: 20px; }
#div_edicao .submenuitem.active { color: #d40707; }

#div_edicao #en {display:none;}
        
#div_edicao .doc_item { width: 45%; overflow: hidden;    border: 1px solid #ddd; padding: 10px;  display:inline-block; vertical-align:top; margin-bottom:10px; }
#div_edicao .doc_item .pic { float: left; width: 20px; margin-right: 10px; line-height: 28px;}
#div_edicao .doc_item .checks { float: left;margin-top: 5px; }
#div_edicao .doc_item .media_input, .doc_item .media_tipodocs { float: left; width: 25%; margin-right: 10px; font-size:11px; }

#div_edicao .doc_item .linha_doc .media_input {float: none; margin: 0;}

#div_edicao .doc_item .linha_doc {
    margin-left: 10px;
    float: left;
    margin-top: 10px;
    width: 100%;
}

#div_edicao .doc_item .btn_remove_media { float: left; }
#div_edicao #uploader_documentos { display: none; }

#div_edicao .redes { display: inline; list-style: none; width: 200px; float: none; margin: 0 auto; }
#div_edicao .redes li { float:left; height:37px; margin:0 10px; }

#div_edicao .btn_eliminar_artigo {
            cursor:pointer;
        }


       h2 { line-height: 36px; color: #d40707; font-size: 22px; }
        

       /* -- EDIT DO UTILIZADOR */
.table_permissoes.disabled {opacity:0.3;
}
        .table_permissoes th { padding-bottom:5px; border-bottom:3px solid #ddd; }
        .table_permissoes td { padding-bottom:5px; border-bottom:1px solid #ddd; padding-top:5px;}
        .table_permissoes .grouplimit td { padding-bottom:5px; border-bottom:1px solid #ddd; padding-top:25px;}

.table_permissoes .siteLimit:after {
        position: absolute;
    content: attr(data-site);
    font-size: 12px;
    left: 0;
    width: 100%;
    background-color: #ddd;
    padding-left: 10px;
}

         