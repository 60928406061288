.loading {
    opacity:0.7;
}
.loading:after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    background: url(../images/progress.gif) no-repeat center center #fff;
    padding: 5px;
    border-radius: 50%;
}

html, body {
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    color: #212121;
    background-color: #eee;
    font-family: 'Lato', Arial, Verdana, Tahoma;
    font-size: 12px;
}

a {
    text-decoration: none;
}

a:active {
    outline: 0;
    text-decoration: none;
}

/* tamanhos */
.t10 {
    font-size: 10px;
}

.t11 {
    font-size: 11px;
}

.t12 {
    font-size: 12px;
}

.t14 {
    font-size: 14px;
}

.t16 {
    font-size: 16px;
}

.t22 {
    font-size: 22px;
}

.t27 {
    font-size: 27px;
}

.t42 {
    font-size: 42px;
}

/* cores */
.azul {
    color: #d40707;
}

.cinzaEE {
    color: #eee;
}

.cinza21 {
    color: #212121;
}

.cinza66 {
    color: #666;
}

.bold {
    font-weight: bold;
}

.lh150 {
    line-height: 1.5em;
}

.hide {
    display: none !important;
}

/* fontes */
.ffSB {
    font-family: 'Lato', Arial,sans-serif;
    font-weight: 700;
}

.ffLight {
    font-family: 'Lato', Arial,sans-serif;
    font-weight: 300;
}

.ffRegular {
    font-family: 'Lato', Arial,sans-serif;
    font-weight: 400;
}

.ffLight.bold, .ffLight .bold, .ffLight b {
    font-weight: 400;
}

/* login */
#master #login {
    border: 0;
    width: 100%;
    min-height: 100%;
    position: fixed;
    margin: 0;
    padding: 0;
    background:url(../images/bg_img.jpg) center center no-repeat;
    left: 0;
    top: 0;
  background-size: cover;
}

#master #login #content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -280px;
    margin-left: -310px;
    width: 500px;
    height: 400px;
    text-align: center;
    background: #fff;
    padding: 60px;
}

#master #login #logo {
    width: 280px;
    height: 95px;
    margin: 0px auto;
    margin-bottom: 30px;
    background: url('../images/logo.png') no-repeat center center;
    float: none;
    text-align: center;
    padding: 0;
}

#master #login #frm {
    width: 300px;
    min-height: 63px;
    margin: 0px auto;
}

#master #login .input_323 {
    line-height: 26px;
    margin: 0;
    border: 1px solid #ccc;
    width: 250px;
    height: 26px;
    background-color: #fff;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    color: #212121;
}

#master #login .input_323:focus {
    line-height: 26px;
    margin: 0;
    border: 1px solid #eee;
    width: 250px;
    height: 26px;
    background-color: #fff;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    color: #212121;
}

#master #login .BtnLogin {
    text-align: center;
    display: block;
    background-color: #333;
    line-height: 32px;
    margin: 0 auto;
    margin-top: 20px;
    border: 0;
    width: 172px;
    height: 32px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

#master #login .login_error {
    font: 11px;
    color: #000;
    font-weight: 700;
    vertical-align: middle;
    text-align: center;
    display: block;
    margin-top: 30px;
}

#master #login .lbl_msg_erro {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle;
    text-align: center;
    display: block;
    width: 80%;
    margin-bottom: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px;
}

#master #login label {
    color: #333;
}


/* masterpage */
#master {
    border: 0;
    width: 100%;
    min-height: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
}

#master #topo {
    border: 0;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0;
    background-color: #d40707;
    color: #fff;
    overflow:hidden;
}

#master #logo {
    width: 160px;
    height: 46px;
    padding: 7px 20px;
    float: left;
    text-align: left;
}

#master .menu-sites {
}
#master .menu-sites li{
    display:inline-block;
}
#master .menu-sites li a{
    display: block;
    padding: 10px 20px;
    height: 20px;
    background-color: #fff;
    margin-top: 19px;
    border-bottom:1px solid #ccc;
}
#master .menu-sites li a.active,
#master .menu-sites li a:hover{
    background-color: #eee;
    border-bottom:1px solid #eee;
}

#master .versite {
    float: left;
    line-height: 60px;
    color: #aaa;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 20px;
    border-left: 1px solid #333;
    padding-left: 20px;
}

#master .versite:hover {
    color: #ddd;
}


#master #info {
    position:absolute;
    top:0;
    right:0;
    min-width: 10px;
    color: #eee;
    padding-right: 20px;
}

#master #info a {
    line-height: 60px;
    color: #eee;
    font-size: 11px;
}

#master #info a:hover {
    color: #ddd;
}

#master #container {
    display: table;
    border: 0;
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    color: #212121;
}

#master .tr {
    display: table-row;
}

#master .td_left {
    display: table-cell;
    width: 180px;
    text-align: left;
    vertical-align: top;
}

#master .td_center {
    display: table-cell;
    width: auto;
    vertical-align: top;
    background-color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 40px 50px;
    min-height: 500px;
}

#master .td_center_ma {
    display: table-cell;
    width: auto;
    vertical-align: top;
    padding: 20px 10px;
    height: 30px;
    text-align: right;
}

#master .td_right {
    display: table-cell;
    width: 10px;
    vertical-align: top;
}


/* -- Menu Lateral -- */
#master .main-menu {
    width: 180px;
    float: right;
    margin-top: 30px;
    list-style: none;
    margin-left: 10px;
}

#master .main-menu li a {
    text-decoration: none;
    display: block;
    top: -1px;
    border-left: 10px solid #EEEEEE;
    background: url('../images/pixel.png') repeat-x left bottom;
    font-size: 12px;
    color: #212121;
    padding: 7px 10px 7px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#master .main-menu li a.active {
    text-decoration: none;
    background: #fff;
    position: relative;
    border-left: 10px solid #fff;
    top: -1px;
}

#master .main-menu li a.grouplimit {
    margin-top: 20px;
}

#master .main-menu li a:hover {
    text-decoration: none;
    color: #666;
}

#master .main-menu li a img {
    margin-bottom: 2px;
}



.spacerv {
    display: block;
    width: 100%;
    clear: both;
    height: 28px;
    line-height: 28px;
}

.spacersmall {
    display: block;
    width: 100%;
    clear: both;
    height: 20px;
    line-height: 20px;
}


/* listagens */
#div_tit_seccao {
    width: 100%;
    color: #d40707;
    font-size: 36px;
    font-weight: 300;
    display: block;
    line-height: 42px;
    overflow: hidden;
}

.btn_azul {
    border: 0;
    display: block;
    padding: 5px 40px;
    text-align: center;
    background-color: #d40707;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
}

.btn_azul:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.btn_preto {
    border: 0;
    display: block;
    padding: 5px 30px;
    text-align: center;
    background-color: #000;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    line-height: 20px;
}

.btn_laranja {
    border: 0;
    display: block;
    padding: 5px 30px;
    text-align: center;
    background-color: #d40707;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    line-height: 20px;
}

/* modal windows */
#modal-content-new {
    display: none;
}

.modal-content-new-title {
    clear: both;
    color: #d40707;
    font-size: 32px;
    font-weight: 300;
}

.modal-content-new-combo {
    clear: both;
    padding: 10px;
}

.modal-content-new-button {
    clear: both;
}



/* EMPRESA */
.tabela_custom {
    border-top: 6px solid #E3E3E3;
}

.tabela_custom thead td {
    color: #d40707;
    font-weight: bold;
}

.btn_remover_contacto, .btn_remover_tag {
    cursor: pointer;
    background: url("../images/trash_16.png") no-repeat scroll center center transparent;
    border: 0 none;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    text-indent: 100px;
    width: 30px !important;
}

.btn_gravar_list {
    padding: 20px;
    cursor: pointer;
    background: url("../images/icon_guardar.png") no-repeat scroll center center transparent;
    border: 0 none;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    text-indent: 100px;
    width: 30px !important;
}

#div_edicao .s_empresa .medio {
    width: 430px;
}

.btn_add_contacto {
    height: 20px;
    float: left;
    line-height: 20px;
}

/* TABELAS AUXILIARES */

#div_edicao .s_empresa .medio {
    width: 430px;
}

.btn_azul.small {
    height: 20px;
    float: left;
    line-height: 20px;
    font-size: 12px;
}

#editores.tabelas .tabela_custom {
    border-top: 0;
}

#editores.tabelas .tabela_custom .c1 {
    width: 10%;
}

#editores.tabelas .tabela_custom .c2 {
    width: 20%;
}

#editores.tabelas .tabela_custom .c3 {
    width: 60%;
}

#editores.tabelas .tabela_custom .c4 {
    text-align: center;
    width: 10%;
}

#editores.tabelas.show .tabela_custom {
    border-top: 6px solid #E3E3E3;
}

#editores.tabelas.show .tabela_custom .c1 {
    width: 10%;
}

#editores.tabelas.show .tabela_custom .c2 {
    width: 80%;
}

#editores.tabelas.show .tabela_custom .c3 {
    text-align: center;
    width: 10%;
}

/* DASHBOARD */


.texto_notedit {
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5em;
    color:#333;
}

input[disabled], textarea[disabled] {
    background: transparent;
    box-shadow: 0 0 0;
    font-size: 14px;
    color:#333;
}


.c2 #div_tit_seccao {
    color: #9C8A4E;
}

.c2 a {
    color: #9C8A4E;
}

.c2 .btn_azul {
    background-color: #9C8A4E;
    color: #fff;
}

.c2 #div_edicao #tabs .active, .c2 #div_edicao #tabs .inactive:hover {
    color: #9C8A4E;
}

.c2 .submenuitem.active {
    color: #9C8A4E;
}


/* -- Cabeçalhos -- */

.menu_breadcrumbs {
    display: inline-block;
    clear: both;
    border-top: 1px solid #ddd;
    margin-top: 5px;
    padding-top: 5px;
    padding-right: 20px;
}

.menu_breadcrumbs li {
    display: inline-block;
    margin-left: 5px;
}

.menu_breadcrumbs li:before {
    content: ' » ';
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
}

.menu_breadcrumbs li:first-child {
    margin-left: 0;
}

.menu_breadcrumbs li:first-child:before {
    display: none;
}

.menu_breadcrumbs li:last-child {
    cursor: default;
    opacity: 0.5;
}


/* -- Histórico de actividades -- */
.alteracoes_recentes_titulo {
    font-size: 18px;
    cursor: pointer;
}

.alteracoes_recentes ul {
    margin-top: 5px;
    display: none;
}

.alteracoes_recentes {
    clear: both;
    border-top: 2px solid #ddd;
    padding-top: 10px;
}


/* -- Dashboard -- */
.dashboard.paineis_holder {
    padding: 0;
    margin-top: 30px;
}

.dashboard .coluna1 {
    width: 44%;
    float: left;
}

.dashboard .painel_3 {
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
    min-height: 320px;
}

.dashboard .painel_1 {
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
    min-height: 320px;
}

.dashboard .painel_2 {
    overflow: hidden;
    width: 45%;
    margin-right: 5%;
    float: left;
    margin-bottom: 10px;
}

.dashboard .titulo_painel {
    background-color: #f3f3f3;
    padding: 15px 10px;
    font-size: 16px;
    border-bottom: 2px solid #DDD;
}



.dashboard #canvas-holder {
    width: 300px;
    height: 400px;
    margin-top: 30px;
    float: left;
}



.dashboard .ddl_holder {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}


.dashboard #list_painel {
    display: block;
    margin-top: 30px;
    list-style-type: none;
    padding: 0;
    width: 45%;
}

.dashboard #list_painel li {
    padding: 2px 0;
    border-bottom: 1px solid #ccc;
}

.dashboard #list_painel li a {
    color: #000;
    display: block;
}

.dashboard #list_painel li a .count {
    float: right;
}


.dashboard .menu-atalhos {
    margin: 0;
    padding: 0;
    margin-top: 20px;
}


.dashboard .menu-atalhos li {
    display: inline-block;
}


.dashboard .menu-atalhos li a {
    border: 1px solid #ddd;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 15px;
    display: block;
    background-color: #fff;
}


.dashboard .menu-atalhos li a:hover {
    background-color: #fafafa;
}


.dashboard .menu-atalhos li img {
    max-height: 70px;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
}


.dashboard .menu-atalhos li span {
    display: inline-block;
    vertical-align: middle;
}


#input_new_item {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top:10px;
}

.lbl_insert {
    float: right;
    padding: 3px 15px;
    background-color: #d40707;
    color: #fff;
    cursor: pointer;
}