/* -- DASHBOARD  CONFIG -- */

.menu-config {
    margin: 0;
    padding: 0;
}

    .menu-config li {
        float: left;
        margin-bottom: 20px;
    }

        .menu-config li a {
            width: 150px;
            border: 1px solid #ddd;
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 15px;
            display: block;
            text-align: center;
            background-color: #fff;
        }

            .menu-config li a:hover {
                background-color: #fafafa;
            }

        .menu-config li img {
            max-height: 70px;
            text-align: center;
            padding-top: 15px;
        }

        .menu-config li span {
            display: block;
            margin-top: 15px;
        }

        .menu-config li.grouplimit {
            clear: both;
        }
