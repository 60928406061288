
/* -- Estilos das tabelas -- */
.watable {
    border-top: 6px #E3E3E3 solid;
    border-radius: 0;
    border-right: 0;
}

.watable .filter {
    display: none;
}

.watable .sort th {
    height: 41px;
    line-height: 41px;
}

.watable .pagesize {
    display: block;
}

.watable .js-click {
    cursor: pointer;
}

.watable .btn-toolbar .dropdown-menu {
    left: auto;
    right: 0;
    text-align: left;
}

.watable .pagesize .dropdown-menu {
    min-width: 60px;
}

.watable .ui-state-highlight {
    height: 30px;
    line-height: 30px;
}

.watable th .pull-left {
    float: none;
}

.watable th .pull-right {
    float: none;
}

.watable .tbcol {
    border-right: 0;
    border-left: 0;
    height: 39px;
    line-height: 39px;
}

.watable .tbcol0 {
    width: 3%;
    text-align: right;
}



/* -- Listagem de artigos -- */



.listagem-principal .tbcol1 {
    width: 5%;
    text-align: center;
}

.listagem-principal .tbcol2 {
    width: 3%;
    text-align: center;
}

.listagem-principal .tbcol3 {
    width: 50%;
    text-align: left;
}
.listagem-principal .tbcol4 {
    width: 10%;
    text-align: left;
}


.listagem-principal .tbcol5 {
    width: 6%;
    text-align: left;
}

.listagem-principal .tbcol6 {
    width: 5%;
    text-align: center;
}

.listagem-principal .tbcol7 {
    width: 4%;
    text-align: center;
}



.listagem-principal .tbcol8 {
    width: 4%;
    text-align: center;
}

.listagem-principal .tbcol9 {
    width: 4%;
    text-align: center;
}

.listagem-principal .ordem {
    background-image: url("../images/drag.png");
    background-repeat: no-repeat;
    background-position: 43% center;
    cursor: n-resize;
    cursor: n-resize;
    overflow: hidden;
    text-align:left;
}

/* -- Listagem de variáveis -- */

.listagem .watable .input_variavel {
      width: 90%;
       overflow: hidden;
    padding: 5px;
}

.listagem .watable .noedit {
    width: 90%;
    display: block;
    overflow: hidden;
    padding: 5px;
    white-space: normal;
    line-height: 1.7em;
}



.listagem.variaveis textarea {
  
    overflow: hidden;
    white-space: nowrap;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
}


.listagem.variaveis textarea:focus {
    overflow: auto;
    white-space: normal;
    min-height: 40px;
    height: auto;
}


.listagem.variaveis .watable .tbcol {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.listagem.variaveis .tbcol.tbcol0 {
    width: 2%;
}

.listagem.variaveis .tbcol.tbcol1 {
    width: 4%;
}

.listagem.variaveis .tbcol.tbcol2 {
    width: 15%;
}

.listagem.variaveis .tbcol.tbcol3 {
    width: 38%;
}

.listagem.variaveis .tbcol.tbcol4 {
    width: 38%;
}

.listagem.variaveis .tbcol.tbcol5 {
    width: 3%;
}



/* -- Listagem de Tabelas -- */


.listagem.tabelas .watable .tbcol {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}


.listagem.tabelas .tbcol.tbcol0 {
    width: 2%;
}
.listagem.tabelas .tbcol.tbcol1 {
    width: 3%;
}
.listagem.tabelas .tbcol.tbcol2 {
    width: 25%;
}
.listagem.tabelas .tbcol.tbcol3 {
    width: 65%;
}
.listagem.tabelas .tbcol.tbcol4 {
    width: 5%;
}



.listagem.tabelas.detail .tbcol.tbcol0 {
    width: 2%;
}
.listagem.tabelas.detail .tbcol.tbcol1 {
    width: 3%;
}
.listagem.tabelas.detail .tbcol.tbcol2 {
    width: 32%;
}
.listagem.tabelas.detail .tbcol.tbcol3 {
    width: 32%;
}
.listagem.tabelas.detail .tbcol.tbcol4 {
    width: 26%;
}
.listagem.tabelas.detail .tbcol.tbcol5 {
    width: 5%;
}



/* -- tabelas_habilitacoes_literarias -- */

.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol0 {
    width: 2%;
}
.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol1 {
    width: 3%;
}
.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol2 {
    width: 3%;
}
.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol3 {
    width: 32%;
}
.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol4 {
    width: 32%;
}
.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol5 {
    width: 10%;
}
.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol6 {
    width: 7%;
}
.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol7 {
    width: 6%;
}
.listagem.tabelas.tabelas_habilitacoes_literarias .tbcol.tbcol8 {
    width: 5%;
}


/* -- tabelas_dominios -- */

.listagem.tabelas.tabelas_dominios .tbcol.tbcol0 {
    width: 2%;
}
.listagem.tabelas.tabelas_dominios .tbcol.tbcol1 {
    width: 3%;
}
.listagem.tabelas.tabelas_dominios .tbcol.tbcol2 {
    width: 85%;
}
.listagem.tabelas.tabelas_dominios .tbcol.tbcol3 {
    width: 5%;
}
.listagem.tabelas.tabelas_dominios .tbcol.tbcol4 {
    width: 5%;
}


/* -- tabelas_categorias_noticas -- */

.listagem.tabelas.tabelas_categorias_noticas .tbcol.tbcol0 {
    width: 2%;
}
.listagem.tabelas.tabelas_categorias_noticas .tbcol.tbcol1 {
    width: 3%;
}
.listagem.tabelas.tabelas_categorias_noticas .tbcol.tbcol2 {
    width: 43%;
}
.listagem.tabelas.tabelas_categorias_noticas .tbcol.tbcol3 {
    width: 42%;
}
.listagem.tabelas.tabelas_categorias_noticas .tbcol.tbcol4 {
    width: 5%;
}
.listagem.tabelas.tabelas_categorias_noticas .tbcol.tbcol5 {
    width: 5%;
}


/* -- tabelas_areas_interesse -- */

.listagem.tabelas.tabelas_areas_interesse .tbcol.tbcol0 {
    width: 2%;
}
.listagem.tabelas.tabelas_areas_interesse .tbcol.tbcol1 {
    width: 3%;
}
.listagem.tabelas.tabelas_areas_interesse .tbcol.tbcol2 {
    width: 43%;
}
.listagem.tabelas.tabelas_areas_interesse .tbcol.tbcol3 {
    width: 42%;
}
.listagem.tabelas.tabelas_areas_interesse .tbcol.tbcol4 {
    width: 5%;
}
.listagem.tabelas.tabelas_areas_interesse .tbcol.tbcol5 {
    width: 5%;
}



/* -- tabelas_subsidiarias -- */

.listagem.tabelas.tabelas_subsidiarias .tbcol.tbcol0 {
    width: 2%;
}
.listagem.tabelas.tabelas_subsidiarias .tbcol.tbcol1 {
    width: 3%;
}
.listagem.tabelas.tabelas_subsidiarias .tbcol.tbcol2 {
    width: 35%;
}
.listagem.tabelas.tabelas_subsidiarias .tbcol.tbcol3 {
    width: 35%;
}
.listagem.tabelas.tabelas_subsidiarias .tbcol.tbcol4 {
    width: 15%;
}
.listagem.tabelas.tabelas_subsidiarias .tbcol.tbcol5 {
    width: 5%;
}
.listagem.tabelas.tabelas_subsidiarias .tbcol.tbcol6 {
    width: 5%;
}



/* -- tabelas_feriados -- */

.listagem.tabelas.tabelas_feriados .tbcol.tbcol0 {
    width: 2%;
}
.listagem.tabelas.tabelas_feriados .tbcol.tbcol1 {
    width: 3%;
}
.listagem.tabelas.tabelas_feriados .tbcol.tbcol2 {
    width: 23%;
}
.listagem.tabelas.tabelas_feriados .tbcol.tbcol3 {
    width: 62%;
}
.listagem.tabelas.tabelas_feriados .tbcol.tbcol4 {
    width: 5%;
}
.listagem.tabelas.tabelas_feriados .tbcol.tbcol5 {
    width: 5%;
}



.listagem.textosvariaveis tbody .tbcol.tbcol0,
.listagem.textosvariaveis thead .tbcol.tbcol0 {
    width: 2%;
    display:none;
}
.listagem.textosvariaveis .tbcol.tbcol1 {
    width: 3%;
}
.listagem.textosvariaveis .tbcol.tbcol2 {
    width: 15%;
}
.listagem.textosvariaveis .tbcol.tbcol3 {
    width: 20%;
}
.listagem.textosvariaveis .tbcol.tbcol4 {
    width: 20%;
}
.listagem.textosvariaveis .tbcol.tbcol5 {
    width: 20%;
}
.listagem.textosvariaveis .tbcol.tbcol6 {
    width: 20%;
}

.listagem.textosvariaveis .tbcol.tbcol7 {
    width: 5%;
}