

.lbl_no_form {
    font-size: 16px;
    display: block;
    text-align: center;
    padding: 40px;
}
.divs.painelshow.overlay:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(0,0,0,0.2);
    top: 0;
    z-index: 10;
}


/* -- Painell de edição -- */
.edit_form {
    width: 61%;
    display: none;
    margin-right: 4%;
    min-height: 100px;
    box-sizing: border-box;
    padding: 40px 70px;
    background-color: #fff;
}

.edit_form.open {
    display: inline-block;
    z-index: 99;
}


.edit_form .lbl_titulo {
    font-size: 24px;
    line-height: 40px;
    color: #3b3b3b;
    font-weight: 300;
    display: block;
    margin-bottom: 10px;
}

.edit_form .edit-close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    padding: 10px;
    cursor: pointer;
}

.edit_form input, .edit_form select {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    margin-bottom: 0;
}

.edit_form .spacerv {
    height: auto;
    margin-bottom: 5px;
}

.edit_form .sep {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin-top: 40px;
    margin-bottom: 30px;
}



/* -- Zona de idiomas -- */

.edit_form .pnl_idioma {
    display: none;
}

.edit_form .pnl_idioma.active {
    display: block;
}

.edit_form .idiomas_edit li {
    font-size: 14px;
    line-height: 25px;
    padding-right: 20px;
    display: inline-block;
    cursor: pointer;
}

.edit_form .idiomas_edit li.active {
    color: #d40707;
}


/* -- Opcoes -- */

.edit_form .opcoes {
    display: none;
}

.edit_form .opcoes.active {
    display: block;
}


.edit_form .input_id {
    width: 15%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
    margin-right: 2%;
}

.edit_form .input_id:disabled {
    opacity: 0.5;
}

.edit_form .input_valor {
    width: 83%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
}

.edit_form .insert {
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}





.edit_form .insert-opcao {
    position: absolute;
    width: 28px;
    height: 28px;
    border: 1px solid #333;
    text-align: center;
    border-radius: 5px;
    line-height: 29px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    right: -40px;
    top: 10px;
}




.edit_form .btn_save_form {
    display: inline-block;
    max-width: 200px;
    margin-top: 20px;
}


.edit_form .btn_preto {
    width: 40px;
    background-color: #ddd;
    float: right;
    margin-top: 20px;
    padding: 10px 5px;
    box-sizing: border-box;
    cursor: pointer;
}



/* -- Listagem de Campos -- */

#itensholder {
    width: 61%;
    display: none;
    margin-right: 4%;
    vertical-align: top;
    min-height: 100px;
    box-sizing: border-box;
    padding: 40px 70px;
    background-color: #fff;
}

#itensholder.open {
    display: inline-block;
}



#itensholder .pnl_id {
    display: inline-block;
    width: 96%;
    font-size: 16px;
    margin: 0 2%;
    margin-bottom: 8px;
    margin-bottom: 15px;
}


/* -- Barra titulo -- */
#itensholder .pnl_id .lbl_nome {
    color: #333;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    padding: 5px;
    background-color: #eee;
    cursor: pointer;
}

#itensholder .pnl_id .lbl_nome .edit-item {
    display: block;
    position: absolute;
    right: 4px;
    top: 50%;
    height: 22px;
    margin-top: -12px;
    border: 1px solid #ccc;
    width: 25px;
    text-align: center;
    font-size: 20px;
    border-radius: 3px;
    color: #ccc;
}

#itensholder .pnl_id .lbl_nome .move-item {
    display: block;
    cursor: move;
    margin-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


/* -- Inputs -- */
#itensholder .pnl_id input, #itensholder .pnl_id textarea, #itensholder .pnl_id select {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    margin-bottom: 0;
    font-size: 12px;
    background-color: #fff;
}

#itensholder .pnl_id textarea {
    height: 100px;
    padding: 5px;
}



/* -- Inputs - Radios/Checks -- */
#itensholder .pnl_id ul {
    height: 40px;
    overflow: hidden;
    line-height: 40px;
}

#itensholder .pnl_id ul li input {
    width: auto;
    height: auto;
}

#itensholder .pnl_id ul li {
    display: inline-block;
    margin-right: 19px;
    height: 20px;
}

/* -- Campos - Larguras -- */
#itensholder .pnl_id.w50 {
    width: 45.5%;
}

#itensholder .pnl_id.w33 {
    width: 29%;
}


/* -- Obriagtorio -- */
#itensholder .pnl_id .obrigatorio.loading:after {
    zoom: 0.5;
}

#itensholder .pnl_id .obrigatorio {
    cursor: pointer;
    background-color: #ddd;
    color: #fff;
    line-height: 28px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.35;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: block;
    text-align: center;
    position: absolute;
    bottom: 14px;
    right: 5px;
}

#itensholder .pnl_id .obrigatorio.on {
    background-color: #d40707;
}




/* -- Bloco disponoveis par adicionar -- */

.itensDisponiveis {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}

.itensDisponiveis .lbl_campo {
    display: inline-block;
    padding: 10px;
    border: 1px solid #ddd;
    margin: 5px;
    width: 45%;
    box-sizing: border-box;
    cursor: pointer;
}

.itensDisponiveis .lbl_campo:after {
    content: '+';
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    height: 20px;
    margin-top: -10px;
    border: 1px solid #aaa;
    width: 20px;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
    color: #aaa;
}

.itensDisponiveis .lbl_campo:hover {
    border-color: #333;
}

.itensDisponiveis .lbl_campo:hover:after {
    border: 1px solid #333;
    color: #333;
}
