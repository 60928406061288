article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary { display: block; }
audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }
audio:not([controls]) { display: none; height: 0; }
[hidden] { display: none; }
html { font-size: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
html, button, input, select, textarea { font-family: sans-serif; }
body { margin: 0; }
a:focus { outline: thin dotted; }
a:hover, a:active { outline: 0; }
h1 { font-size: 1em; margin: 0; }
h2 { font-size: 1em; margin: 0; }
h3 { font-size: 1em; margin: 0; }
h4 { font-size: 1em; margin: 0; }
h5 { font-size: 1em; margin: 1.67em 0; }
h6 { font-size: 1em; margin: 2.33em 0; }
abbr[title] { border-bottom: 1px dotted; }
b, strong { font-weight: bold; }
blockquote { margin: 1em 40px; }
dfn { font-style: italic; }
mark { background: #ff0; color: #000; }
p, pre { margin: 1em 0; }
pre, code, kbd, samp { font-family: monospace,serif; _font-family: 'courier new',monospace; font-size: 1em; }
pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }
q { quotes: none; }
q:before, q:after { content: ''; content: none; }
small { font-size: 75%; }
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }
dl, menu, ol, ul { margin: 1em 0; }
dd { margin: 0 0 0 40px; }
menu, ol, ul { padding: 0 0 0 40px; }
nav ul, nav ol { list-style: none; list-style-image: none; }
img { border: 0; -ms-interpolation-mode: bicubic; font-size: 10px; color: transparent; }
svg:not(:root) { overflow: hidden; }
figure { margin: 0; }
form { margin: 0; }
fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: .35em .625em .75em; }
legend { border: 0; padding: 0; white-space: normal; *margin-left: -7px; }
button, input, select, textarea { font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; border-radius :0; padding:0;}
button, input { line-height: normal; }
button, input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; -webkit-appearance: button; *overflow: visible; }
button[disabled], input[disabled] { cursor: default; }
input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; *height: 13px; *width: 13px; }
input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button { -webkit-appearance: none; }
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }
textarea { overflow: auto; vertical-align: top; }
table { border-collapse: collapse; border-spacing: 0; }
* { list-style: none; position:relative; }
li {list-style: none;}
img { border: 0; }


/* ----------
GLOBAL RESETS
-------------------------- */
form { height: 100%; }
html, body { font-family: 'Lato',Arial, Helvetica,sans-serif; margin: 0; padding: 0; border: 0; height: 100%; width: 100%; background-color:#fff; color: #666; }
h1, h2, h3, h4, h5 { margin: 0; border: 0; padding: 0; font-weight: normal; }
.hidden { display: none; }
a { color: #333; }
a, a:hover, a:active, a:focus, a:visited { text-decoration: none; outline: none; }
ul { margin: 0; padding: 0; }


/* ----------
CORES
-------------------------- */
.branco { color: #fff; }
.preto { color: #000; }
.cinza33 { color:#333; }
.cinza44 { color:#444; }
.cinza52 { color:#525252; }
.cinzaEB { color:#EBEBEB; }

.vermelho { color:#FF0000; }

/* -------------
FONTES
------------------------ */
/*
@font-face { font-family: 'museo_sans100'; src: url('/css/fonts/museosans-100-webfont.eot'); src: url('/css/fonts/museosans-100-webfont.eot?#iefix') format('embedded-opentype'), url('/css/fonts/museosans-100-webfont.woff') format('woff'), url('/css/fonts/museosans-100-webfont.ttf') format('truetype'), url('/css/fonts/museosans-100-webfont.svg#museo_sans100') format('svg'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'museo_sans300'; src: url('/css/fonts/museosans-300-webfont.eot'); src: url('/css/fonts/museosans-300-webfont.eot?#iefix') format('embedded-opentype'), url('/css/fonts/museosans-300-webfont.woff') format('woff'), url('/css/fonts/museosans-300-webfont.ttf') format('truetype'), url('/css/fonts/museosans-300-webfont.svg#museo_sans300') format('svg'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'museo_sans500'; src: url('/css/fonts/museosans-500-webfont.eot'); src: url('/css/fonts/museosans-500-webfont.eot?#iefix') format('embedded-opentype'), url('/css/fonts/museosans-500-webfont.woff') format('woff'), url('/css/fonts/museosans-500-webfont.ttf') format('truetype'), url('/css/fonts/museosans-500-webfont.svg#museo_sans500') format('svg'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'museo_sans700'; src: url('/css/fonts/museosans-700-webfont.eot'); src: url('/css/fonts/museosans-700-webfont.eot?#iefix') format('embedded-opentype'), url('/css/fonts/museosans-700-webfont.woff') format('woff'), url('/css/fonts/museosans-700-webfont.ttf') format('truetype'), url('/css/fonts/museosans-700-webfont.svg#museo_sans700') format('svg'); font-weight: normal; font-style: normal; }

.ffThin {font-family: 'museo_sans100', Arial, sans-serif;}
.ffLight {font-family: 'museo_sans300', Arial, sans-serif;}

.ffRegular {font-family: 'museo_sans500', Arial, sans-serif;}
.ffLight b, .ffLight strong {font-family: 'museo_sans500', Arial, sans-serif; font-weight:normal; }

.ffBold {font-family: 'museo_sans700', Arial, sans-serif;}
.ffRegular b, .ffRegular strong {font-family: 'museo_sans700', Arial, sans-serif; font-weight:normal; }
*/
.arial { font-family:Arial, sans-serif; }
/* -------------
TAMANHOS
----------------------- */
.t9 { font-size: 9px; }
.t10 { font-size: 10px; }
.t11 { font-size: 11px; }
.t12 { font-size: 12px; }
.t13 { font-size: 13px; }
.t14 { font-size: 14px; }
.t15 { font-size: 15px; }
.t16 { font-size: 16px; }
.t17 { font-size: 17px; }
.t18 { font-size: 18px; }
.t19 { font-size: 19px; }
.t20 { font-size: 20px; }
.t21 { font-size: 21px; }
.t22 { font-size: 22px; }
.t23 { font-size: 23px; }
.t24 { font-size: 24px; }
.t25 { font-size: 25px; }
.t26 { font-size: 26px; }
.t27 { font-size: 27px; }
.t28 { font-size: 28px; }
.t30 { font-size: 30px; }
.t32 { font-size: 32px; }
.t34 { font-size: 34px; }
.t35 { font-size: 35px; }
.t36 { font-size: 36px; }
.t38 { font-size: 38px; }
.t40 { font-size: 40px; }
.t42 { font-size: 42px; }
.t44 { font-size: 44px; }
.t45 { font-size: 45px; }
.t48 { font-size: 48px; }
.t50 { font-size: 50px; }
.t55 { font-size: 55px; }
.t60 { font-size: 60px; }
.t70 { font-size: 70px; }
.t80 { font-size: 80px; }
.t90 { font-size: 90px; }
.t100 { font-size: 100px; }
.t110 { font-size: 110px; }
.ls2 {letter-spacing: 2px;}
.ls5 {letter-spacing: 5px;}
.ls10 {letter-spacing:10px;}
.lsn10 {letter-spacing: -10px;}

/* -------------
OUTROS
----------------------- */
.bold { font-weight: bold; }
.bottom { vertical-align: bottom; }
.top { vertical-align: top; }

.lh50 { line-height: 50%; }
.lh80 { line-height: 80%; }
.lh100 { line-height: 100%; }
.lh110 { line-height: 110%; }
.lh120 { line-height: 120%; }
.lh140 { line-height: 140%; }
.lh150 { line-height: 150%; }
.lh160 { line-height: 160%; }
.lh180 { line-height: 180%; }
.lh170 { line-height: 170%; }
.lh200 { line-height: 200%; }
.lh250 { line-height: 250%; }

.shadowFF {text-shadow: 1px 1px 0px #FFFFFF;}
.shadow00 {text-shadow: 1px 1px 0px #000000;}
.shadow66 {text-shadow: 1px 1px 0px #666;}
.underline { text-decoration: underline; }
.lthrough { text-decoration: line-through; }
.pointer { cursor: pointer; }
.italic { font-style: italic; }
.upper { text-transform: uppercase; }
.lower { text-transform: lowercase; }

.clear { clear: both; }
.hide { display: none; }
.dblock { display:block; }
.fleft { float:left; }
.fright { float:right; }


.nowrap { white-space: nowrap; }
.center { text-align: center; }
.overflowHidden { overflow: hidden; }
.anim02 { -webkit-transition: all 0.2s ease; -moz-transition: all 0.2s ease; -o-transition: all 0.2s ease; transition: all 0.2s ease; }
.respimg { width:100%; height:auto; display:block; }

/* LARGURAS */
.wfull { max-width:1600px; display: block; margin: 0 auto; width:96%; }
.w1600 { max-width: 1600px; margin: 0 auto; width: 100%; }
.w1024 { width: 1024px; display: block; margin: 0 auto; }
.w980 { max-width: 980px; width:95%; display: block; margin: 0 auto; }
.w940 { width: 940px; display: block; margin: 0 auto; }
.w880 { width: 880px; display: block; margin: 0 auto; }

/* CHECK BROWSER */
#checkbrowser { display:none; position:fixed; top:0; left:0; z-index:2000; width:100%; line-height:25px; height:30px; margin:0 auto; text-align:center; background:#FDF2AB; border-bottom:1px solid #A29330; }
#checkbrowser .btn_close_browser { padding:2px 10px; display:block; }
#checkbrowser .update_browser { line-height:30px; display:block; }

/* COOKIES */
#cookiescontainer { display: none; clear:both; position:fixed; bottom:0; left:0; z-index:1000; width:100%; min-height:40px; text-align:left; background:#CCC;-webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4); -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4); }
#cookiescontainer .text { float:left; width:75%; padding:12px; display:block;vertical-align: middle;}
#cookiescontainer .accept { float:right; width:20%; min-width:100px; max-width:130px; line-height:38px; height:40px; display:inline-block; vertical-align:middle; text-align:center; background:#333; text-transform:uppercase; margin:10px; }
.mini #cookiescontainer { text-align:center;}
.mini #cookiescontainer .text { clear:both; width:100%; padding-left:0%; }
.mini #cookiescontainer .accept { clear:both; width:130px; float:left; text-align:center; margin:0 auto;}
.mini #cookiescontainer .wrapper_text { text-align: center; width:90%; margin:0 auto;}
.mini #cookiescontainer .wrapper_link { text-align: center; width: 130px; margin:0 auto; }


/* -- VALIDATION ERRORS -- */
.validator { position: absolute; right:12px; top:-12px; width: 20px; height: 20px; text-align: center; }
.validator .erroMark { cursor: pointer; z-index: 99; position: relative; width: 20px; height: 20px; background-color:#E46328; text-align: center; color: #fff; font-size: 25px; display: block; line-height: 30px; border-radius: 50%; }
.validator .erroDetail { display: none; white-space: nowrap;width:auto; padding: 5px 22px 5px 15px; background-color:#E46328; z-index: 98; color: #fff; position: relative; font-size: 12px; margin-top: -20px; text-align: left; border-radius: 10px;height:25px; line-height:25px;position:absolute;right:0;top:12px; }
.validator:hover .erroDetail { display: block; }